<router>
{
    name: 'Analytics',
}
</router>
<template lang="pug">
v-container
    PortalAnalytics
</template>
<script>

import PortalAnalytics from '@/components/portal/analytics';

export default {
    meta: {
        // role: 'portalcontact'
    },
    components: {
        PortalAnalytics
    },
    data () {
        return {

        };
    },
    methods: {

    },
    computed: {
    },
    mounted () {
        window.portalanalytics = this;
    }
};
</script>

<style scoped>
</style>
